import React,{useState, useEffect} from 'react';
import { Space, Button, Tag, Image, Popconfirm, Table, message, Input, Tooltip } from 'antd';
import SystemPage from "../SystemPage/SystemPage.js";
import { useNavigate } from 'react-router-dom'
import {connect } from 'react-redux'
import {getAddImgsUrl, addImgTableData, nameInputChang, addressInputChang, delTableList, setDataSource, setourNum} from '../../../actions/action.js'
import './UploadingImg.css'
import {insertPictureFiles, showAllPictureFiles} from '../../../api/index.js'
import { getsionStorage, removesionStorage } from '../../../utils/sionStorage.js';
 // 只能输入数字的input组件
 const formatNumber = (value) => new Intl.NumberFormat().format(value);
 const NumericInput = (props) => {
   const { value, onChange } = props;
 
   const handleChange = (e) => {
     const { value: inputValue } = e.target;
     const reg = /^-?\d*(\.\d*)?$/;
 
     if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
       onChange(inputValue);
     }
   }; // '.' at the end or only '-' in the input box.
 
   const handleBlur = () => {
     let valueTemp = value;
     if (value.charAt(value.length - 1) === '.' || value === '-') {
       valueTemp = value.slice(0, -1);
     }
 
     onChange(valueTemp.replace(/0*(\d+)/, '$1'));
   };
 
   const title = value ? (
     <span className="numeric-input-title">{value !== '-' ? formatNumber(Number(value)) : '-'}</span>
   ) : (
     'Input a number'
   );
   return (
     <Tooltip trigger={['focus']} title={title} placement="topLeft" overlayClassName="numeric-input">
       <Input
         {...props}
         onChange={handleChange}
         onBlur={handleBlur}
         className='our-num'
         placeholder="Input a number"
         maxLength={25}
       />
     </Tooltip>
   );
 };
const UploadImgPage = (props) =>{
  const {datas, getAddImgsUrl, addImgTableData, nameInputChang, addressInputChang, delTableList, setDataSource, data, ourNum, setourNum } = props
  const navigate = useNavigate()
  const projectId = getsionStorage('projectId')
  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      className: 'drag-visible',
      render:(_, record,index) => index+1
    },
    {
      title: '圖層名稱備註',
      className: 'drag-visible',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => {
        return (<input className='name-input' placeholder='请填写名称' value={record.name} onChange={(e)=>nameInputChang(e.target.value,record.id)} ></input>)
      }
    },
    {
      title: '位      址',
      dataIndex: 'address',
      key: 'address',
      render: (_, record) => {
        return (<input className='name-input' placeholder='1-10位数字+字母组合地址' value={record.address} onChange={(e) => addressInputChang(e.target.value,record.id)} ></input>)
      }
    },
    {
        title: '檔案數量',
        dataIndex: 'num',
        key: 'num',
      },
    // {
    //   title: '稀  有  度',
    //   key: 'uncommon',
    //   dataIndex: 'uncommon'
    // },
    {
      title: '图片',
      key: 'uncommon',
      dataIndex: 'uncommon',
      // className: 'table-img-tag-box',
      render: (_, record) => {
        const imgs = record.imgs && record.imgs.length>0? record.imgs.slice(0,2):record.imgs
        console.log(record)
        return <div className='table-img-tag-box'>
         { imgs.map((item) => {
          // console.log(item)
          return (
              <Tag closable={false} className='table-img-tag' onClose={(e) => closeTableImgs(e,item, record.id)} key={item}>
              <Image src={item.imageurl} className='table-img-td'></Image>
     </Tag>)
        })}
             <div className='emit-imgs' onClick={(e) => uploadImg(e,record)}>{imgs.length>0?'...': '图片为空'}</div>
        </div>
      }
    },
    {
      title: '操      作',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button className='compile-btn' style={{borderRadius: '10px'}}  onClick={(e) => uploadImg(e,record)}>上传/编辑图档</Button>
          {/* <Button className='compile-btn' >编辑</Button> */}
          <Popconfirm
              title="确定删除吗?"
              onConfirm={() => delList(record.id)}
              okText="确定"
              cancelText="取消"
            >
                <Button className='dele-btn'>删除</Button>
            </Popconfirm>
        </Space>
      ),
    },
  ];
  // 删除页表数据
  const delList = (id) => {
    delTableList(id)
  }
  const [isSystemPage, setisSystemPagI] = useState(true) // 上传图档组件展示
  // const [imgsUrl, setImgsUrl] = useState({}) // 每个图层中的所有图片路径
  const [recordDatas, setRecordDatas] = useState(null) // 点击上传图档时传给组件的数据
  useEffect(async () => {
    if (!projectId){
      message.info('请先从第一步填写')
      removesionStorage('projectId')
      navigate('/createProject')
    } 
    // else {
    //   try {
    //     const imgsDataAll = await showAllPictureFiles({projectId})
    //     if (imgsDataAll && imgsDataAll.length > 0) {
    //       let imgsData = [];
    //       imgsDataAll.map((item, i) => {
    //         imgsData.push({
    //           name: item.layerName,
    //           id: item.id,
    //           imgs: [...item.pictuerFilesList],
    //           key: i,
    //           address: '',
    //           num: item.pictuerFilesList.length
    //         })
    //       })
    //       // console.log(imgsData)
    //       setDataSource([...imgsData])
    //       console.log(imgsDataAll[0].generateNumber)
    //       setourNum(imgsDataAll[0].generateNumber)
    //     } else {
    //       setDataSource([{
    //         key: 0,
    //         num: 0,
    //         name: 'new layer',
    //         imgs:[],
    //         address: '',
    //         index: 0,
    //         id: new Date().getTime()
    //       }])
    //     }
    //   } catch (err){
    //     setDataSource([{
    //       key: 0,
    //       num: 0,
    //       name: 'new layer',
    //       imgs:[],
    //       address: '',
    //       index: 0,
    //       id: new Date().getTime()
    //     }])
    //   }
    // }
  },[])
  // 点击上传图档按钮
  const uploadImg = (e,data) => {
    console.log(data)
    setisSystemPagI(false)
    setRecordDatas({...data})
  }
  // 添加图层表格按钮点击事件
  const addImgTable = () => {
    addImgTableData()
  }
  // 获得导入图片的所有路径
  const getAddImgs = (d) => {
    console.log(d)
      getAddImgsUrl(d)
      setisSystemPagI(true)
  }
  // 点击下一步事件
  const nextStep = () => {
    console.log(datas)
    let requesdata = []
    let createNum = 1
    let datasNum = 1
    for(var i in datas) {
      if (datas[i].num != 0){
        createNum *= datas[i].num
      }
      requesdata[i] = {}
      requesdata[i].name = datas[i].name
      requesdata[i].remarks = datas[i].address
      requesdata[i].imgs = []
      for (var j in datas[i].imgs){
        requesdata[i].imgs = [...requesdata[i].imgs,datas[i].imgs[j].imageurl]
      }
    }
    if (datas.find(item => item.imgs.length>0) && createNum >= ourNum){
      insertPictureFiles({
        projectId,
        layersOrder: requesdata,
        generateNumber: ourNum
    })
      navigate('/createProject/generateWork')
    } else {
      message.info('请最少上传一张图片或希望生成数量过大')
    }
  }
    return <div className='content-margin-top'>
        {isSystemPage?(<div>
          <div className='upload-img-title' style={{display: 'flex',justifyContent: 'space-between',alignItems: 'center'}}>
            <div>項目名稱：{data.projectName}</div>
            <div>希望生成数量：
            <NumericInput
              style={{
                width: 120,
              }}
              value={ourNum}
              onChange={setourNum}
            />
            </div>
            <Button className='bg-btn' style={{width: '10%'}} onClick={addImgTable}>添加图层</Button>
          </div>
          <Table
          className='upload-img-table'
      pagination={false}
      dataSource={datas}
      columns={columns}
      ></Table>
          <Button className='bg-btn' style={{width: '15%',marginTop: '1.25em'}} onClick={nextStep}>下一步</Button>
        </div>): <SystemPage getaddImgs={getAddImgs} recordData={recordDatas}/>}
    </div>
}
const mapStateToProps = (state) => {
  return {
    datas: state.redux.data,
    data: state.material.data,
    ourNum: state.ourNum.data.ourNum
  }
}
const mapDispatchToProps = {getAddImgsUrl,addImgTableData,nameInputChang, addressInputChang, delTableList, setDataSource, setourNum}
const DataUploadImgPage = connect(mapStateToProps,mapDispatchToProps)(UploadImgPage)
export default DataUploadImgPage                