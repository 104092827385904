import Axios from '../utils/axios.js';
import qs from 'qs'
// 用户注册
function signUp (data){
    console.log(qs.stringify({...data}))
    return Axios.post('shipApi/launchPad/signUp', qs.stringify({...data}))
}
// 查看指定用户信息
function checkUserInfo(data) {
    return Axios.post('shipApi/launchPad/checkUserInfo', qs.stringify({...data}))
}
// 用户创建新项目
function createNewProject(data) {
    return  Axios.post('/shipApi/launchPad/createNewProject', qs.stringify({...data}))
}
// 展示该项目全部白名单
function showAllWhiteLists(data) {
    return  Axios.post('/shipApi/launchPad/showAllWhiteLists', qs.stringify({...data}))
}
// 获取七牛云token
function getQiniuToken() {
    return  Axios.get('/shipApi/launchPadAdmin/getQiniuToken')
}
// 上传图档
function insertPictureFiles(data) {
    // return  Axios.post('/shipApi/launchPad/insertPictureFiles', JSON.stringify(data))
    return Axios({
        url: '/shipApi/launchPad/insertPictureFiles',
        method: 'post',
        data: JSON.stringify(data),
        headers: {"Content-Type":"application/json"},
    })
}
// 查看指定项目信息
function checkProjectInfo(data) {
    return Axios({
        url: '/shipApi/launchPad/checkProjectInfo',
        method: 'post',
        data: qs.stringify({...data}),
    })
}
// 展示该项目全部已生成NFT
function showAllNFTs(data) {
    return Axios({
        url: '/shipApi/launchPad/showAllNFTs',
        method: 'get',
        params: data,
        headers: {"Content-Type":"application/json"},
    })
}
// 推送选中的NFT至PINATA
function uploadNFTsToPinata(data) {
    return Axios({
        url: '/shipApi/launchPad/uploadNFTsToPinata',
        method: 'post',
        data: JSON.stringify(data),
        headers: {"Content-Type":"application/json"},
    })
}
// 新增白名单
function createNewWhitelist(data) {
    return  Axios.post('/shipApi/launchPad/createNewWhitelist', qs.stringify({...data}))
}
// 创建和生成合约
function createContract(data) {
    return  Axios.post('/shipApi/launchPad/createContract', qs.stringify({...data}))
}
// /shipApi/launchPad/checkSelfProjectInfo 查看本人旗下所有项目信息
function checkSelfProjectInfo(data) {
    return  Axios.post('/shipApi/launchPad/checkSelfProjectInfo', qs.stringify({...data}))
}
// 展示该项目全部图档/shipApi/launchPad/showAllPictureFiles
function showAllPictureFiles(data) {
    return Axios({
        url: '/shipApi/launchPad/showAllPictureFiles',
        method: 'get',
        params: data,
        headers: {"Content-Type":"application/json"},
    })
}
export {
    signUp,
    checkUserInfo,
    createNewProject,
    showAllWhiteLists,
    getQiniuToken,
    insertPictureFiles,
    checkProjectInfo,
    showAllNFTs,
    uploadNFTsToPinata,
    createNewWhitelist,
    createContract,
    checkSelfProjectInfo,
    showAllPictureFiles
}