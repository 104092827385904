import { useEffect, useState } from "react"
import { Card, Spin  } from "antd"
import CaseCard from "../../../components/caseCard/caseCard"
import { checkSelfProjectInfo } from "../../../api"
const ItemRecord = () => {
    const [ProjectInfoAll, setProjectInfoAll] = useState([])
    const [isProject, setisProject] = useState(false)
    useEffect(async () => {
        setisProject(true)
        try{
            const data =await  checkSelfProjectInfo()
            setProjectInfoAll([...data])
        } catch {}
        setisProject(false)
    },[])
    return <div className="content-margin-top">
        <div>
            <Spin  spinning={isProject}>
                {/* <CaseCard ProjectInfoAll = {ProjectInfoAll} name = 'itemRecord'></CaseCard> */}
                {isProject? <div className="no-Project" styel={{ textAlign: 'center'}}>您还没有创建项目,点击创建发行项目新建一个吧</div>: <CaseCard ProjectInfoAll = {ProjectInfoAll} name = 'itemRecord'></CaseCard>}
            </Spin>
        </div>
    </div>
}
export default ItemRecord