const material = (state= {data: {
    projectName: '',
    price: 0,
    currency: 'eth',
    isBlind: '0',
    issueNumber: 3,
    releaseDate: '',
    publisher: '',
    walletAddress: '',
    generateNumber: 0,
    projectStatus: '0'
}}, action) => {
    switch(action.type){
        case 'changInput': 
        return {
            data: {...state.data, [action.payload.name]: action.payload.val}
        }
        break;
        case 'setData':
            return {
                data: {...action.payload}
            }
            break;
        default: 
        return state
    }
}
export default material