import React, { Component, Fragment } from "react";
import {BrowserRouter as Router, Route, Link, Routes, useNavigate, useLocation} from 'react-router-dom'
import { ConfigProvider, DatePicker, message, Col, Row, Image } from 'antd';
import NavMenus from "./components/navMenu/index.js";
import { setsionStorage  } from "./utils/sionStorage.js"; 
 // 引入reoutemap文件
 import routerMap from "./router/routerMap.js";
 import FrontendAuth from "./router/FrontendAuth.js";
// 由于 antd 组件的默认文案是英文，所以需要修改为中文
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'antd/dist/antd.min.css';
import './styles/app.css'
import logo from './assets/logo.png'
moment.locale('zh-cn');
// 登录和链接钱包按钮组件
const Btn =  () => {
  const navigate = useNavigate()
// 点击登录注册按钮
  const loginBtn = () => {
    navigate('/register')
  }
  // 点击连接钱包按钮
  const walletLink = () =>{
    setsionStorage('token', 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxNTYzMDY0NTMxOTAxNjQ0ODAwIiwiaXNzIjoiMTU2MzA2NDUzMTkwMTY0NDgwMCIsImlhdCI6MH0.lbhmyErP3afV0Xdnlb-MR9A3vJPwWsnAv_TfJP5sEer4Dvnn9YsPAyKjjbrOEPQfGEC4UD-iAULlBz1AdENWzA')
    message.info('登录成功')
  }
  return (<div>
    <button className="logo-btn" onClick={loginBtn}>註冊/登入</button>
    <button className="wallet-btn" onClick={walletLink }>連結錢包</button>
  </div>)
}
const NavBarComponent = () => {
  const navmenuList = [
          {text: '首頁', path: '/'},
          {text: '創建發行項目', path: '/createProject'},
          {text: '我的', path: '/my'},
          {text: '資費方案', path: '/expensesCase'},
          {text: '在線協助', path: '/createProject'}
      ]
      return (<div className="navBar">
    <Row align="middle" className="navBar-row">
     <Col span={9}>
        <div className="logo-box">
          <Image src={logo} preview={false} className="logo"></Image>
        </div>
      </Col>
       <Col >
         <Row  align="middle" justify="space-between">
         <Col>
            <NavMenus  navmenuList={navmenuList} location={useLocation()} ></NavMenus>
          </Col>
           <Col push={1}>
            <Btn/>
           </Col>
         </Row>
       </Col>
     </Row>
     </div>)
}
function App(){
  return ( <ConfigProvider locale={zhCN}>
    <Router>
      <NavBarComponent></NavBarComponent>
      <div className="border-bom"></div>
           <FrontendAuth routerConfig={routerMap}/>
    </Router>
  </ConfigProvider>);
}
export default App;
