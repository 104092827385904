import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {Provider} from 'react-redux'
import store from './store'
import "./styles/reset.css";
// const mapDispatchToProps = {increase}
// const mapStateToProps = (state)=> {
//     return {
//         cocunt: state.cocunt
//     }
// }
// const AppContainer = connect(mapStateToProps,mapDispatchToProps)(App)
ReactDOM.render(<Provider store={store}><App/></Provider>, document.getElementById("root"));