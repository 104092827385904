import Index from '../pages/index/index.js';
// 引入注册页面
import Register from '../pages/register'
// 引入创建发行项目的页面
import CreateProject from '../pages/createProject/index.js';
import MaterialPage from "../pages/createProject/material/material.js";
import UploadImgPage from "../pages/createProject/UploadImgPage/UploadImgPage.js";
import GenerateWork from '../pages/createProject/GenerateWork/Generatework.js';
// import SystemPage from "../pages/createProject/SystemPage/SystemPage.js";
import WhitelistPage from "../pages/createProject/WhitelistPage/WhitelistPage.js";
import PageproducerPage from "../pages/createProject/PageproducerPage/PageproducerPage.js";
 // 引入我的页面
 import My from "../pages/My/index.js";
 import PersonalData from "../pages/My/personalData/personalData.js";
 import MyWallet from "../pages/My/myWallet/myWallet.js";
 import MyAccount from "../pages/My/myAccount/myAccount.js";
 import PaymentScheme from "../pages/My/PaymentScheme/PaymentScheme.js";
 import TransactionRecord from '../pages/expensesCase/transactionRecord/transactionRecord.js'
 import ItemRecord from '../pages/My/itemRecord/itemRecord.js';
 // y引入资费方案页面
 import ExpensesCase from '../pages/expensesCase'
 import CaseLists from '../pages/expensesCase/caseLists'
const routerMap = [
    {path: '/', name: 'index', element: <Index/>, auth: false},
    {path: '/register', name: 'register', element: <Register/>, auth: false},
    {path: '/createProject', name: 'createProject', element: <CreateProject/>, 
    children: <MaterialPage/>,
    auth: true, 
    childrens: [
        {
            path: '/createProject/',
            auth: true,
            parent: <CreateProject/>,
            element: <MaterialPage/>
        },
        {
            path: '/createProject/uploadImgPage',
            auth: true,
            parent: <CreateProject/>,
            element: <UploadImgPage/>
        },
        {
            path: '/createProject/whitelistPage',
            auth: true,
            parent: <CreateProject/>,
            element: <WhitelistPage/>
        },
        {
            path: '/createProject/pageproducerPage',
            auth: true,
            parent: <CreateProject/>,
            element: <PageproducerPage/>
        },
        {
            path: '/createProject/generateWork',
            auth: true,
            parent: <CreateProject/>,
            element: <GenerateWork/>
        },
    ]
},
    {path: '/my', name: 'my', element: <My/>, auth: false,
    children: <PersonalData/>,
    auth: false, 
    childrens: [
        {
            path: '/my/',
            auth: false,
            parent: <My/>,
            element: <PersonalData/>
        },
        {
            path: '/my/myWallet',
            auth: false,
            parent: <My/>,
            element: <MyWallet/>
        },
        {
            path: '/my/myAccount',
            auth: false,
            parent: <My/>,
            element: <MyAccount/>
        },
        {
            path: '/my/paymentScheme',
            auth: false,
            parent: <My/>,
            element: <PaymentScheme/>
        },
        {
            path: '/my/itemRecord',
            auth: false,
            parent: <My/>,
            element: <ItemRecord/>
        },
    ]
},
    {path: '/expensesCase', name: 'expensesCase', element: <ExpensesCase/>, auth: false},
    {path: '/expensesCase/caseLists', name: 'caseLists', element: <CaseLists/>, auth: false},
    {path: '/expensesCase/transactionRecord', name: 'transactionRecord', element: <TransactionRecord/>, auth: false},
 ]
 export default  routerMap