const getsionStorage = (key) => {
    return sessionStorage.getItem(key)
}
const setsionStorage = (key, val) => {
    sessionStorage.setItem(key, val)
}
const removesionStorage = (key) => {
    sessionStorage.removeItem(key)
}
export {
    getsionStorage,
    setsionStorage,
    removesionStorage
}