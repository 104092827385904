import React, { Component, useEffect } from 'react';
import NavMenu from '../../components/navMenu';
import { Outlet, useLocation } from  'react-router-dom';
import './index.css'
import { setsionStorage } from '../../utils/sionStorage';
const CreateProject = (props) => {
    const navmenuList = [
        {text: '項目基本資料', path: '/createProject'},
        {text: '上傳圖檔', path: 'uploadImgPage'},
        {text:'图片生成', path:'generateWork'},
        {text: '白名單管理', path: 'whitelistPage'},
        {text: '頁面生成', path: 'pageproducerPage'},
    ]
    const location = useLocation()
    useEffect(() => {
        return () => {
            setsionStorage('location', location.pathname)
        }
    })
    return (<div className='content-box'>
        <NavMenu navmenuList={navmenuList} location={useLocation()}/>
        <Outlet/>
    </div>)
}
export default CreateProject