import React ,{ Component } from 'react';
import { Route, Navigate, Routes, useLocation} from 'react-router-dom';
import { getsionStorage } from '../utils/sionStorage';
const frontendAuthLocation =  (FrontendAuth)=> {
    Pathnameompont.displayName = `FrontendAuthLocation${getDisplayName(FrontendAuth)}`
    function Pathnameompont (props) {
        const location = useLocation()
        const {pathname} = location
        return <FrontendAuth pathname={pathname} {...props}></FrontendAuth>
    }
    function getDisplayName(FrontendAuth) {
        return FrontendAuth.displayName || FrontendAuth.name || 'Component'
    }
    return Pathnameompont
}
class FrontendAuth extends Component{
    constructor(props){
        super(props)
    }
    checkPath = (routers,path) => {
        for (const data of routers) {
            if (data.path===path) return data
            if (data.childrens) {
                const res = this.checkPath(data.childrens, path)
                if (res) return res
            }
        }
        return null
    }
    render (){
        const { routerConfig, pathname} = this.props;
        const isLogin = getsionStorage('token');
        // const targetRouterConfig = routerConfig.find(item => pathname===item.path)
        const targetRouterConfig = this.checkPath(routerConfig, pathname)
        console.log(targetRouterConfig)
        if (targetRouterConfig && !targetRouterConfig.auth && !isLogin){
            // return <Routes><Route path={targetRouterConfig.path} element={targetRouterConfig.element}></Route></Routes>
            if (!targetRouterConfig.parent && !targetRouterConfig.childrens){
                return <Routes><Route  path={targetRouterConfig.path} element={targetRouterConfig.element}>
                    </Route></Routes>
            } else {
                const chirildpPath = targetRouterConfig.path.split(/\//g)
                console.log(chirildpPath)
                if (chirildpPath.length < 3){
                    return <Routes><Route  path={targetRouterConfig.path} element={targetRouterConfig.element}>
                        <Route  path='' element={targetRouterConfig.children}></Route>
                    </Route></Routes>
                }
                return <Routes>
                            <Route  path={chirildpPath[1]} element={targetRouterConfig.parent}>
                                <Route path={chirildpPath[2]? chirildpPath[2]: ''} element={targetRouterConfig.element}></Route>
                            </Route>
                    </Routes>
            }
        }
        if (isLogin) {
            if (pathname === '/login') {
                return <Navigate to='/'/>
            } else {
                if (targetRouterConfig) {
                    // if (!targetRouterConfig.childrens){
                    //     return <Routes><Route  path={targetRouterConfig.path} element={targetRouterConfig.element}>
                    //         </Route></Routes>
                    // } else {
                    //     const chirildpPath = targetRouterConfig.path.split(/\//g)
                    //     if (chirildpPath.length < 3){
                    //         return <Routes><Route  path={targetRouterConfig.path} element={targetRouterConfig.element}>
                    //             <Route  path='' element={targetRouterConfig.children}></Route>
                    //         </Route></Routes>
                    //     }
                    //     return <Routes>
                    //             <Route  path={chirildpPath[1]} element={targetRouterConfig.parent}>
                    //                 <Route path={chirildpPath[2]? chirildpPath[2]: ''} element={targetRouterConfig.element}></Route>
                    //             </Route>
                    //         </Routes>
                    // }
                    if (!targetRouterConfig.parent && !targetRouterConfig.childrens){
                        return <Routes><Route  path={targetRouterConfig.path} element={targetRouterConfig.element}>
                            </Route></Routes>
                    } else {
                        const chirildpPath = targetRouterConfig.path.split(/\//g)
                        console.log(chirildpPath)
                        if (chirildpPath.length < 3){
                            return <Routes><Route  path={targetRouterConfig.path} element={targetRouterConfig.element}>
                                <Route  path='' element={targetRouterConfig.children}></Route>
                            </Route></Routes>
                        }
                        return <Routes>
                                    <Route  path={chirildpPath[1]} element={targetRouterConfig.parent}>
                                        <Route path={chirildpPath[2]? chirildpPath[2]: ''} element={targetRouterConfig.element}></Route>
                                    </Route>
                            </Routes>
                    }
                } else {
                    return <Navigate to='/error'/>
                }
            }
        } else {
            if (targetRouterConfig && targetRouterConfig.auth) {
                return <Navigate to='/register'/>
            } else {
                return <Navigate to='/error'/>
            }
        }
    }
}
const FrontendAuthLocations = frontendAuthLocation(FrontendAuth)
export default FrontendAuthLocations