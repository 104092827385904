import * as qiniu from 'qiniu-js'
import {useState} from 'react'
//生成随机字符串 防止文件重名
    function createId() {
        var len = 16;
        var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
        var maxPos = $chars.length;
        var pwd = '';
        for (var i = 0; i < len; i++) {
            pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return pwd;
    }

//生成当天日期为文件夹 防止一个文件夹下图片过多
    function formatDate(value = Date.now(), format = "YMD") {
        const formatNumber = n => `0${n}`.slice(-2);
        const date = new Date(value);
        const formatList = ["Y", "M", "D", "h", "m", "s"];
        const resultList = [];
        resultList.push(date.getFullYear().toString());
        resultList.push(formatNumber(date.getMonth() + 1));
        resultList.push(formatNumber(date.getDate()));
        resultList.push(formatNumber(date.getHours()));
        resultList.push(formatNumber(date.getMinutes()));
        resultList.push(formatNumber(date.getSeconds()));
        for (let i = 0; i < resultList.length; i++) {
            format = format.replace(formatList[i], resultList[i]);
        }
        return format;
    }

    function upload(file) {
        var filename = createId()
        // 获取input上传的文件路径
        // var file = that.target.files[0]
        var uploadname = ''
        //通过mime判断文件后缀名
        if (file.type == 'image/gif') {
            uploadname = `${filename}.gif`
        } else if (file.type == 'image/jpeg') {
            uploadname = `${filename}.jpg`
        } else if (file.type == 'image/svg+xml') {
            uploadname = `${filename}.svg`
        } else if (file.type == 'image/png') {
            uploadname = `${filename}.png`
        } else if (file.type == 'image/webp'){
            uploadname = `${filename}.png`
        }
        //上传到七牛云路径
        var key = `launchpadimgs/${formatDate()}/${uploadname}`
        return [key]
    }
    function qinniu (file, key,token, complete,next) {
        //初始化配置
        const observable = qiniu.upload(file, key, token, null, null)
        const subscription = observable.subscribe({
            next(res) {
                if(next){
                    next(res)
                }
                // console.log('next')
                // console.log(res)
            },
            error(err) {
                // console.log('error')
            },
            complete(res) {
                complete(res)
                // console.log('complete')
                // console.log(res)
            }
        }) // 上传开始
    }
    export {
        qinniu,
        upload
    }
