import React from 'react';
import './index.css'
import { Table } from 'antd';
const TableComponent = (props) =>{
    return <div>
        <Table columns={props.columns} dataSource={props.data} pagination={false} className='upload-img-table'/>
    </div>
}
export default TableComponent
// columns={columns} pagination={false} dataSource={dataSource} rowKey="index"
//             components={{
//               body: {
//                 wrapper: DraggableContainer,
//                 row: DraggableBodyRow,
//               },
//             }}