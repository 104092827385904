import { message } from "antd"
const reducer = (state = {data: [
    {
      key: 0,
      num: 0,
      name: 'new layer',
      imgs:[],
      address: '',
      index: 0,
      id: new Date().getTime()
    },
  ]
}, action) => {
    switch(action.type){
        case 'setDataSource':
            return {
                data: [...action.payload]
            }
        case 'getAddImgsUrl':
            console.log(action.payload)
            console.log(state.data)
            const d = action.payload
            const datas = state.data.filter(item => {
                if (item.id === d.id){
                  item.imgs = d.imgs
                  item.num = d.imgs.length
                  return item
                } else {
                  return item
                }
              })
            console.log(datas)
            return {
                data: [...datas]
            }
            break;
        case 'addImgTableData': 
            return {
                data:[...state.data, {
                    key: 1,
                    num: 0,
                    name: 'new layer',
                    address: '',
                    index: 1,
                    id: new Date().getTime(),
                    imgs:[]
                  }]
            }
            break;
        case 'nameInputChang':
            let nameData = [...state.data]
            nameData = nameData.filter(item => {
                if (item.id === action.payload.id){
                    item.name = action.payload.val
                    return item
                } else {
                    return item
                }
            })
            return {
                data: [...nameData]
            }
            break;
        case 'addressInputChang':
            let addressData = [...state.data]
            const val = action.payload.val
            console.log(val)
            const regExp = /^[0-9A-Za-z]{0,10}$/
            console.log(regExp.test(val))
            if (regExp.test(val)){
                addressData = addressData.filter(item => {
                    if (item.id === action.payload.id){
                        item.address = action.payload.val
                        return item
                    } else {
                        return item
                    }
                })
            } else {
                message.info('请输入10位字母+数字')
                addressData = addressData.filter(item => {
                    if (item.id === action.payload.id){
                        item.address = action.payload.val.substring(0,action.payload.val.length-1)
                        return item
                    } else {
                        return item
                    }
                }) 
            }
            return {
                data: [...addressData]
            }
            break;
        case 'delTableList':
            let delData = [...state.data]

            const index = delData.findIndex(item => item.id === action.payload.id)
            delData.splice(index, 1)
            return {
                data: [...delData]
            }
            break;
        default:
            return state
    }
}
export default reducer