import React, { Component } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row, message  } from 'antd';
import './index.css'
import {getsionStorage} from '../../utils/sionStorage'
// 高阶组件包装
function widthHook(WrapCompontent) {
    // 设置别名
    QiLincompont.displayName = `WithHook${getDisplayName(WrapCompontent)}`
    function QiLincompont(props) {
      const navigate = useNavigate()
      return <WrapCompontent to={navigate} {...props}></WrapCompontent>
    }
      // 设置别名
  function getDisplayName(WrapCompontent) {
    return WrapCompontent.displayname || WrapCompontent.name || 'Component'
  }
  return QiLincompont
  }
class NavMenu extends Component{
    state = {
        isactive: '' // 导航被点击的索引值
    }
    componentDidMount(){
        this.getHref()
    }
    componentDidUpdate(prevProps){
        if (!prevProps.location || prevProps.location.pathname === this.props.location.pathname) return 
        this.getHref()
    }
    // 获取href并改变isactive值
    getHref () {
        const href = window.location.href
        const path = href.replace('http://nft.zikudata.com', '').trim()
        if (path === '/createProject' || path === '/my'|| path === '/'){
            this.setState({
                isactive: `${path}`
            })
        } else {
            this.setState({
                isactive: path
            })
        }
    }
    // 导航点击事件
    clickIndex(i, e){
        this.setState({
            isactive: i 
        })
        // 获取上次离开时创建发行的页面路径
        const location = getsionStorage('location')
        const href = window.location.href
        const path = href.replace('http://nft.zikudata.com', '').trim()
        console.log('i'+ path)
        console.log(i,location,path,  i !== '/' )
        // 如果不是首次去创建发行项目,则自动跳转到上次离开是的页面
        if (i == '/createProject' && location && i !== '/'){
            e.preventDefault()
            this.props.to(location)
        }
    }
    // 创建发行页面的导航点击事件
    clickIndexMes(i){
        // const href = window.location.href
        // const path = href.replace('http://localhost:3000/', '').trim()
        // console.log(path)
        // const lists = this.props.navmenuList
        // let index = lists.findIndex((item) => {
        //     return path.indexOf(item.path) !== -1
        // })
        // index = index=== -1? '0':index
        // console.log(i[0],index)
        // if (i[0]>index) {
        //     message.info('请完成以上步骤');
        // } else if (path === 'createProject'){
        //     this.props.to('/createProject')
        // } else {
        //     this.props.to(i[1])
        // }
        message.info('请按照步骤完成操作哦');
    }
    render (){
        const Lists = this.props.navmenuList.map((item,index) => {
            let link;
            if (this.props.navmenuList[0].path !== '/createProject' && this.props.navmenuList[0].path !== '/my'){
                console.log(this.state.isactive, item.path)
                if (item.path === '/'){
                    link =<Link to={item.path} onClick={this.clickIndex.bind(this,item.path)} className={this.state.isactive === item.path? 'active': ''} dataID={index}>{item.text}</Link>
                } else {
                    link =<Link to={item.path} onClick={this.clickIndex.bind(this,item.path)} className={this.state.isactive.indexOf(item.path) !== -1? 'active': ''} dataID={index}>{item.text}</Link>
                }
            } else if (this.props.navmenuList[0].path == '/createProject'){
                if (item.path === '/createProject'){
                    link = <div className={this.state.isactive === item.path?'active': ''} onClick={this.clickIndexMes.bind(this,[index, item.path])} dataID={index}>{item.text}</div>
                } else {
                    link = <div className={this.state.isactive.indexOf(item.path) !== -1?'active': ''} onClick={this.clickIndexMes.bind(this,[index, item.path])} dataID={index}>{item.text}</div>
                }
            } else {
                if (item.path === '/my'){
                    link =<Link to={item.path} onClick={this.clickIndex.bind(this,item.path)} className={this.state.isactive === item.path?'active': ''}  dataID={index}>{item.text}</Link>
                } else {
                    link =  <Link to={item.path} onClick={this.clickIndex.bind(this,item.path)} className={this.state.isactive.indexOf(item.path) !== -1? 'active': ''} dataID={index}>{item.text}</Link>
                }
            }
            return (<Col key={index}>
                   {link}
                </Col>)
        })
        return (<div className='navmenu-top'>
            <Row gutter={60} className='navmenu-box'>
               {Lists}
            </Row>
       </div>)
    }
}
const NavMenus = widthHook(NavMenu)
export default NavMenus