import React from "react";
import { Col, Row, Button, Select  } from 'antd';
import {useNavigate} from'react-router-dom';
import './index.css';
import CaseComponent from "../../components/case";
const ExpensesCase = ()=>{
    const navigate = useNavigate(); 
    const GoTransactionRecord = () =>{
        navigate('/expensesCase/transactionRecord')
    }
    return (<div className="content-box expenses-case-box">
        <Row justify="space-between" align='middle' className="expenses-case-title-box">
            <Col>
                <div className="expenses-case-title">
                    <span className="expenses-case-title-txt">選擇資費方案</span>
                    {/* <input className="expenses-case-title-input" ></input> */}
                    <Select defaultValue="num1" className="expenses-case-title-input">
                        <Option value="num1">發行資費方案</Option>
                        <Option value="num2">賦能資費方案</Option>
                        <Option value="num3">全能資費方案</Option>
                        </Select>
                </div>
            </Col>
            <Col span={4}>
                <Button className='bg-btn' style={{width: '100%'}} onClick={GoTransactionRecord}>查看收支明細</Button>
            </Col>
        </Row>
        <div className="expenses-case-content">
            <CaseComponent/>
            <CaseComponent/>
            <CaseComponent/>
        </div>
        <div>
            <Button className="back-btn" style={{float: 'unset', marginTop: '2em'}} onClick={() => {navigate('/expensesCase/caseLists')}}>下一步</Button>
        </div>
    </div>)
}
export default ExpensesCase