const ourNum = (state= {data: {
    ourNum: '10'
}}, action) => {
    switch(action.type){
        case 'setourNum':
        return {
            data: {ourNum: action.payload}
        }
        break;
        default: 
        return state
    }
}
export default ourNum