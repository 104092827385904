import React, {useState, useEffect} from 'react';
import './SystemPage.css'
import { Col, Row, Button, Tag, Image, Modal, Progress, Spin, Slider   } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {qinniu, upload} from '../../../utils/qiniu.js';
import {getQiniuToken} from '../../../api/index.js'
const SystemPage = (props) =>{
    const [tableLists, setTableLists] = useState([0])
    const [imgsURL, setImgsURL] = useState([]) // 图片的所有本地路径
    const [imgRef, setImgRef] = useState('') // 导入图片的所有路径
    const [uploadUrl, setuploadUrl] = useState([])
    const [isModalVisible, setIsModalVisible] =  useState(false) // 确认删除model是否显示
    const [isexample, setIsexample] = useState(true) // 示例是否显示
    const [token, setToken] = useState('')// 七牛token
    const [loading, setLoading] = useState(false)
    const [uploadImageMes, setuploadImageMes] = useState([{
        name: '',
        imgs: []
    }])// 图档名称图片和稀有度
    useEffect(() => {
            if(props.recordData && props.recordData.imgs.length>0){
                setImgsURL([...props.recordData.imgs])
                console.log('开始')
                setIsexample(false)
            }
            getQiniuToken().then((res)=>{
                setToken(res)
            })
    }, [])
    useEffect(()=> {
        if (uploadUrl.length>0) {
            setImgsURL([...imgsURL, ...uploadUrl])
        }
    },[uploadUrl])
    // 新增按钮点击事件
    const addTableBTN = () => {
        document.getElementsByClassName('fileInput')[0].click()
    }
    // 图片关闭事件
    const CloseImg = (e,data) => {
        console.log(e,data)
        e.preventDefault()
        setImgRef(data)
        showModal()
    }
     // 对话框显示
    const showModal = () => {
        setIsModalVisible(true);
      };
      // 点击删除对话框的确认按钮
      const handleOk = () => {
        setIsModalVisible(false);
        const url = imgs.filter(item => {
            console.log(item.imageurl, imgRef)
            return item.imageurl !== imgRef.imageurl
        })
        if (url.length < 1) {
            setIsexample(true)
        }
        setImgsURL([...url])
      };
    // 点击删除对话框的取消按钮
      const handleCancel = () => {
        setIsModalVisible(false);
      };
  // 图片上传成功事件
  const uploadImgSuccess = async (e) => {
    setIsexample(false)
    for (let i in e.target.files){
        setLoading(true)
        if (!isNaN(+i)) {
            // 将图片上传到七牛
            const [key]= upload(e.target.files[+i])
            await qinniu(e.target.files[+i], key, token, async (res) => {
                setuploadUrl([{
                    name: '',
                    imageurl: `https://static.reflink.cn/${res.key}?growEdition=100`,
                    id: new Date().getTime()
                }])
                setLoading(false)
            }, (res) => {
                setLoading(true)
                console.log(parseInt(res.total.percent))
            })
        }
    }

  }
  // 稀有度框值改变
  const growEditionSizeToInputChg = (val, id) => {
    console.log(val, id)
    // const id = e.target.id
    const imgsList = imgsURL.filter((item) => {
        if (item.id == id) {
            let imgsUrl
            if (item.imageurl.indexOf('?growEdition=') === '-1') {
                imgsUrl = item.imageurl
            } else {
                imgsUrl = item.imageurl.split('?growEdition=')[0]
            }
            return item.imageurl = `${imgsUrl}?growEdition=${val}`
        } else {
            return item
        }
    })
    setImgsURL([...imgsList])
  }
// 图档名称改变事件
const nameInputChg  = (e) => {
    const id = e.target.id
    const imgsList = imgsURL.filter((item) => {
        if (item.id == id) {
            return item.name = e.target.value
        } else {
            return item
        }
    })
    setImgsURL([...imgsList])
}
  // 示例图档名称和图片自由度设定的html
  const ExampleDiv = ()=> {
    if (isexample){
        return <Row className='system-page-content-chrbox' align="middle" justify='space-around'>
        <Col span={16}>
            <div className='name-input'><input value='示例:眼睛'  disabled='disabled'></input></div>
        </Col>
        <Col span={2}>
            <div className='system-page-content-chrbox-but'>
                {/* <Button type="primary" shape="round">導入圖檔</Button> */}
                <Tag closable={false}>
                     <Image src="https://static.reflink.cn/launchpadimgs/20220902/NRW3mctCDQz37ZNH.png"></Image>
                </Tag>
            </div>
        </Col>
        <Col span={4}>
            <div className='name-input'>
                <Slider/>
            </div>
        </Col>
    </Row>
    } else {
        return null
    }
  }
  // 确认新增按钮
  const addImgs = () => {
    // console.log()
    props.recordData.imgs = imgsURL
    props.getaddImgs(props.recordData)
  }
    return <div>
        <div className='system-page-title'>
            <div className='system-page-title-left'>新增圖層</div>
            <div>
                <span>圖層名稱備註: {props.recordData.name}</span>
                {/* <input className='system-page-title-input' value={props.recordData.name}></input> */}
            </div>
        </div>
        <Spin spinning={loading} tip="Loading...">
        <div className='system-page-content'>
            <Row wrap> 
                <Col span={24}>
                    <Row align='middle' justify='space-between' className='system-page-content-totle'>
                        <Col span={16}>
                            <div>圖檔名稱</div>
                        </Col>
                        <Col span={4}>
                            <div>稀有度設定</div>
                        </Col>
                    </Row>
                    <ExampleDiv/>
                </Col>
                {tableLists && tableLists.map(() => {return (<Col span={24}>
        {imgsURL.map(item => {
            return (<Row className={`system-page-content-chrbox`} align="middle" justify='space-around'>
            <Col span={16}>
                <div className='name-input'><input id={item.id} value={item.name} onChange={nameInputChg}></input></div>
            </Col>
            <Col span={2}>
                <div className='system-page-content-chrbox-but'>
                    <Tag closable onClose={(e) => CloseImg(e,item)}>
                        <Image src={item.imageurl} ></Image>
                    </Tag>
                </div>
            </Col>
            <Col span={4}>
                {/* <div className='name-input'><input id={item.id} onChange={growEditionSizeToInputChg} value={item.imgs.split('?growEdition=')[1]} maxLength='2'></input></div> */}
                <div className='name-input'>
                    <Slider
                        tooltip={{
                        open: true,
                        }}
                        onChange={(val) => growEditionSizeToInputChg(val,item.id)}
                        value={item.imageurl.split('?growEdition=')[1]}
                    />
                </div>
            </Col>
        </Row>)
        })}
    </Col>)})}
                <Col span={24}>
                    <Row justify='start'>
                         <Button type='primary' icon={<PlusOutlined/>} shape='round' className='add-table-btn' onClick={addTableBTN}>导入图档</Button>
                         <input type="file" id="file" multiple className='fileInput' accept="image/*" onChange={uploadImgSuccess}/>
                    </Row>
                </Col>
                <Col span={24}>
                    <div className='system-page-content-line'></div>
                </Col>
                <Col span={24}>
                    <Row justify='end'>
                         <Button shape='round' className='isok-table-btn' onClick={addImgs}>確認 新增</Button>
                    </Row>
                </Col>
            </Row>
        </div>
        </Spin>
        <Modal  visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <p>确认删除吗</p>
      </Modal>
    </div>
}
export default SystemPage