 const  getAddImgsUrl = (i) => {
    return {
        type: 'getAddImgsUrl',
        payload: {...i}
    }
}
const addImgTableData = () => {
    return {
        type: 'addImgTableData'
    }
}
const nameInputChang = (val,id) => {
    return {
        type: 'nameInputChang',
        payload: {val,id}
    }
}
const addressInputChang = (val,id) => {
    return {
        type: 'addressInputChang',
        payload: {val,id}
    }
}
const delTableList = (id) => {
    return {
        type: 'delTableList',
        payload: id
    }
}
const setDataSource = (data) => {
    return {
        type: 'setDataSource',
        payload: data
    }
}
const changInput = (data) =>{
    return {
        type: 'changInput',
        payload: data
    }
}
const setourNum = (data) => {
    return {
        type: 'setourNum',
        payload: data
    }
}
const setlacation = (data) => {
    return {
        type: 'setlacation',
        payload: data
    }
}
const setData = (data) => {
    return {
        type: 'setData',
        payload: data
    }
}
export {
    getAddImgsUrl,
    addImgTableData,
    nameInputChang,
    addressInputChang,
    delTableList,
    setDataSource,
    changInput,
    setourNum,
    setlacation,
    setData
}