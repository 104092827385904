import React, {useState, useEffect} from 'react';
import './PersonalData.css'
import { Col, Row, Form, Input, Button, Space} from 'antd';
import mydata from '../../../assets/mydata.png'
const PersonalData = () => {
    const [isInput, setIsInput] = useState(true)
    const editBTN = () => {
        setIsInput(!isInput)
    }
    useEffect(() => {

    }, [])
    return (<div className='personal-data-box content-margin-top'>
        <Row justify='space-around' align='middle' gutter={10}>
            <Col span={8} className='personal-data-img'>
                <img src={mydata}></img>
            </Col>
            <Col span={12}>
                <Form className='personal-data-from' colon={false}>
                    <Form.Item label="用戶編號" >
                        {isInput? (<div className='personal-data-from-content'>123344566</div>): (<Input className='personal-data-from-input'/>)}
                    </Form.Item>
                    <Form.Item label={<Space size='large'><span>暱 </span><span>稱</span></Space>} >
                        {isInput? (<div className='personal-data-from-content'>Stan7217</div>): (<Input className='personal-data-from-input'/>)}
                    </Form.Item>
                    <Form.Item label="真實姓名" >
                        {isInput? (<div className='personal-data-from-content'>盧志恆</div>): (<Input className='personal-data-from-input'/>)}
                    </Form.Item>
                    <Form.Item label="公司名稱" >
                        {isInput? (<div className='personal-data-from-content'>元浪潮信息科技有限公司</div>): (<Input className='personal-data-from-input'/>)}
                    </Form.Item>
                    <Form.Item label="手  機  號" >
                        {isInput? (<div className='personal-data-from-content'>0900000111</div>): (<Input className='personal-data-from-input'/>)}
                    </Form.Item>
                    <Form.Item label="營業執照" >
                        {isInput? (<div className='personal-data-from-content'>A56473890-4098376</div>): (<Input className='personal-data-from-input'/>)}
                    </Form.Item>
                    <Form.Item className='personal-data-from-btn-box'>
                        <Button className='personal-data-from-btn' onClick={editBTN} disabled>{isInput? '编辑': '确定'}</Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    </div>)
}
export default PersonalData