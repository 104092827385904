import React, { Component } from 'react';
import './register.css';
import { Col, Row, Form, Input, Button, Select, message } from 'antd';
import { Navigate } from 'react-router-dom'
import NFT from '../../assets/NFT.png'
import UserIcon from '../../assets/usericon.png'
import gongsi from '../../assets/gongsi.png'
import shouji from '../../assets/shouji.png'
import yanzhengma from '../../assets/yanzhengma.png'
import yingye from '../../assets/yingye.png'
import qianbao from '../../assets/qianbao.png'
import {signUp} from '../../api/index.js'
import withNavigate from '../../utils/withNavigate';
// const IconImage = <div>
//     <img src={UserIcon} className='user'></img>
// </div>
 class RegisterPages extends Component {
    fromRef = React.createRef()
    companyFromRef = React.createRef()
    state = {
        userType: '1', // 用户类型
        userName: '', // 姓名
        companyName: '', // 公司名称
        phoneNumber: '', // 手机号
        businessLicense: '', // 营业执照
        walletAddress: '' // 钱包地址
    }
    // 提交表单且数据验证成功后回调事件
    onFinish = async () => {
        const data =await signUp(this.state)
        if(data) {
            message.info('注册成功')
            this.props.to('/')
        }
    }
    // 提交表单且数据验证失败后回调事件
    onFinishFailed = () => {
        console.log('失败')
    }
    // input框与state数据联动
    InputChange = (attr, val) => {
        this.setState({
            [attr]: val
        })
    }
    // 失去焦点自动校验
    inputBlur= (name) => {
        this.fromRef.current.validateFields([name])
    }
    // 手机号自定义校验规则
    phoneNumberValidator = (val) => {
        const reg = /^[1][3-9]\d{9}$|^([5|6|9])\\d{7}$|^[0][9]\\d{8}$|^[6]\\d{7}$/
        if (reg.test(val)) {
            return Promise.resolve()
        } 
        return Promise.reject(new Error('手机号格式错误'))
    }
    // submitFrom = async () =>{
    //     try {
    //         const values = await this.fromRef.current.validateFields();
    //         console.log('Success:', values);
    //       } catch (errorInfo) {
    //         console.log('Failed:', errorInfo);
    //       }
    // }
    render (){
        return (<div className='register-box'>
            <Row justify='space-between' align='middle'>
                <Col>
                    <img src={NFT} className='NFT-img'></img>
                </Col>
                <Col>
                    <div className='register-text'>註冊</div>
                    <div className='from-box'>
                        { this.state.userType === '1'? <Form
                            ref={this.fromRef}
                            name="basic"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                            autoComplete="off"
                            >
                                <Form.Item
                                validateFirst
                                colon={false}
                                className='register-type'
                                label='類型'
                                >
                                    {/* <Input placeholder='姓名' className='userInput' addonBefore={<img src={UserIcon} className='user-icon'></img>}/> */}
                                    <Select defaultValue='1'  onChange={e => this.InputChange('userType',e)} value={this.state.userType}>
                                        <Option value='1'>個人用戶註冊</Option>
                                        <Option value='2'>企业用戶註冊</Option>
                                    </Select>
                                </Form.Item>
                                <div className='from-line'></div>
                            <Form.Item 
                                name= 'userInput'
                                validateTrigger= 'onBlur'
                                rules={[
                                    {
                                    required: true,
                                    message: '姓名不能为空'}
                                ]}
                            >
                                <Input placeholder='姓名' className='userInput ' addonBefore={<img src={UserIcon} className='user-icon'></img>} onChange={e => this.InputChange('userName',e.target.value)} value={this.state.userName}/>
                            </Form.Item>
                            <Form.Item
                                validateFirst
                                name = 'phoneNumber'
                                validateTrigger= 'onBlur'
                                rules={[
                                    {
                                        required: true,
                                        message: '手机号码不能为空'
                                    },
                                    {
                                        validator: (_, value) => this.phoneNumberValidator(value)
                                    }
                                ]}
                                >
                                <Input placeholder='手機號' className='userInput ' onChange={e => this.InputChange('phoneNumber',e.target.value)} value={this.state.phoneNumber} addonBefore={<img src={shouji} className='user-icon'></img>}/>
                            </Form.Item>
                            <Form.Item
                            >
                                <Input placeholder='驗證碼' className='userInput yanzhengma-input' addonBefore={<img src={yanzhengma} className='user-icon'></img>}/>
                                <button className='getyanzhengma'>獲取驗證碼</button>
                            </Form.Item>
                            <Form.Item
                                name = 'walletAddress'
                                validateTrigger= 'onBlur'
                                rules={[
                                    {
                                    required: true,
                                    message: '电子钱包不能为空'}
                                ]}
                            >
                                <Input placeholder='電子錢包位址' className='userInput ' onChange={e => this.InputChange('walletAddress',e.target.value)} value={this.state.walletAddress} addonBefore={<img src={qianbao} className='user-icon'></img>}/>
                            </Form.Item>
                            <Form.Item>
                                <div className='goBack-btn-box'>
                                    <Button className='goBack-btn'>
                                        上一步
                                    </Button>
                                </div>
                                <Button htmlType="submit" className='submit-btn' onClick={this.submitFrom}>
                                確認
                                </Button>
                            </Form.Item>
                        </Form>: <Form
                            ref={this.companyFromRef}
                            name="basic"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                            autoComplete="off"
                            >
                                <Form.Item
                                validateFirst
                                colon={false}
                                className='register-type'
                                label='類型'
                                >
                                    {/* <Input placeholder='姓名' className='userInput' addonBefore={<img src={UserIcon} className='user-icon'></img>}/> */}
                                    <Select defaultValue='1'  onChange={e => this.InputChange('userType',e)} value={this.state.userType}>
                                        <Option value='1'>個人用戶註冊</Option>
                                        <Option value='2'>企业用戶註冊</Option>
                                    </Select>
                                </Form.Item>
                                <div className='from-line'></div>
                            <Form.Item 
                                name= 'userInput'
                                validateTrigger= 'onBlur'
                                rules={[
                                    {
                                    required: true,
                                    message: '姓名不能为空'}
                                ]}
                            >
                                <Input placeholder='姓名' className='userInput ' addonBefore={<img src={UserIcon} className='user-icon'></img>} onChange={e => this.InputChange('userName',e.target.value)} value={this.state.userName}/>
                            </Form.Item>

                            <Form.Item
                                className={this.state.userType === '1'?'userTypecompany': ''}
                                name = 'companyName'
                                validateTrigger= 'onBlur'
                                rules={[
                                    {
                                    required: true,
                                    message: '公司名称不能为空'}
                                ]}
                            >
                                <Input placeholder='公司名稱' className='userInput ' onChange={e => this.InputChange('companyName',e.target.value)} value={this.state.companyName} addonBefore={<img src={gongsi} className='user-icon'></img>}/>
                            </Form.Item>
                            <Form.Item
                                validateFirst
                                name = 'phoneNumber'
                                validateTrigger= 'onBlur'
                                rules={[
                                    {
                                        required: true,
                                        message: '手机号码不能为空'
                                    },
                                    {
                                        validator: (_, value) => this.phoneNumberValidator(value)
                                    }
                                ]}
                                >
                                <Input placeholder='手機號' className='userInput ' onChange={e => this.InputChange('phoneNumber',e.target.value)} value={this.state.phoneNumber} addonBefore={<img src={shouji} className='user-icon'></img>}/>
                            </Form.Item>
                            <Form.Item
                            >
                                <Input placeholder='驗證碼' className='userInput yanzhengma-input' addonBefore={<img src={yanzhengma} className='user-icon'></img>}/>
                                <button className='getyanzhengma'>獲取驗證碼</button>
                            </Form.Item>
                            <Form.Item
                                validateTrigger= 'onBlur'
                                className={this.state.userType === '1'?'userTypecompany': ''}
                                name = 'businessLicense'
                                rules={[
                                    {
                                    required: true,
                                    message: '营业执照不能为空'}
                                ]}
                            >
                                <Input placeholder='營業執照' className='userInput ' onChange={e => this.InputChange('businessLicense',e.target.value)} value={this.state.businessLicense} addonBefore={<img src={yingye} className='user-icon'></img>}/>
                            </Form.Item>
                            <Form.Item
                                name = 'walletAddress'
                                validateTrigger= 'onBlur'
                                rules={[
                                    {
                                    required: true,
                                    message: '电子钱包不能为空'}
                                ]}
                            >
                                <Input placeholder='電子錢包位址' className='userInput ' onChange={e => this.InputChange('walletAddress',e.target.value)} value={this.state.walletAddress} addonBefore={<img src={qianbao} className='user-icon'></img>}/>
                            </Form.Item>
                            <Form.Item>
                                <div className='goBack-btn-box'>
                                    <Button className='goBack-btn'>
                                        上一步
                                    </Button>
                                </div>
                                <Button htmlType="submit" className='submit-btn'>
                                確認
                                </Button>
                            </Form.Item>
                        </Form>}
                    </div>
                </Col>
            </Row>
        </div>)
    }
}
const RegisterPage = withNavigate(RegisterPages)
export default RegisterPage
