import React, {useEffect}from 'react';
import { Col, Row, Form, Input,  Button, Space, message} from 'antd';
import { useNavigate } from 'react-router-dom';
import './PageproducerPage.css'
import pageproducer from '../../../assets/pageproducer.png'
import { getsionStorage, removesionStorage } from '../../../utils/sionStorage';
const PageproducerPage = () =>{
    const navigate = useNavigate()
    useEffect(() => {
        if (!getsionStorage('projectId')){
          message.info('请先从第一步填写')
          removesionStorage('projectId')
          navigate('/createProject')
          return
        }
      },[])
    return <div className='pageproducer-box content-margin-top'>
        <Row justify='space-between' align='middle'>
            <Col span={13}>
                <Form>
                    <Form.Item label={<Space size='large'><span>域 </span> <span>名 </span></Space>} className='form-item-box'>
                        <Input className='form-item-input'/>
                    </Form.Item>
                    <Form.Item label="项目LOGO/头像">
                        <Input className='form-item-input'/>
                    </Form.Item>
                    <div className='add-img-btn'><Button type="primary" shape='round' className='add-img'>導入圖檔</Button></div>
                    <Form.Item label="铸 造 页 联 节" className='form-item-box'>
                        <Input className='form-item-input'/>
                    </Form.Item>
                    <Form.Item label="白 名 单 联 结" className='form-item-box'>
                        <Input className='form-item-input'/>
                    </Form.Item>
                    <Form.Item label="赋 能 页 连 结" className='form-item-box'>
                        <Input className='form-item-input'/>
                    </Form.Item>
                    <Form.Item className='form-item-btn'>
                        <Button shape='round' >確認生成</Button>
                    </Form.Item>
                </Form>
            </Col>
            <Col span={9} className='pageproducer-img'>
                <img src={pageproducer}/>
            </Col>
        </Row>
    </div>
}
export default PageproducerPage