import React from 'react';
import './MyWallet.css';
import { Col, Row, Button } from 'antd';
import TableComponent from '../../../components/tableComponent';
const columns = [

    {
      title: '排  序',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: '圖層名稱備註',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '位      址',
      dataIndex: 'address',
      key: 'address',
    },
    {
        title: '檔案數量',
        dataIndex: 'num',
        key: 'num',
      },
    {
      title: '稀  有  度',
      key: 'uncommon',
      dataIndex: 'uncommon'
    },
  ];
  const data = [
    {
      key: '1',
      index: 1,
      name: '髮型',
      num: 1,
      address: 'XXXXXXXXXXXXXXXXXXXXXX'
    },
    {
        key: '1',
        index: 1,
        name: '髮型',
        num: 1,
        address: 'XXXXXXXXXXXXXXXXXXXXXX'
      },
      {
        key: '1',
        index: 1,
        name: '髮型',
        num: 1,
        address: 'XXXXXXXXXXXXXXXXXXXXXX'
      },
      {
        key: '1',
        index: 1,
        name: '髮型',
        num: 1,
        address: 'XXXXXXXXXXXXXXXXXXXXXX'
      },
      {
        key: '1',
        index: 1,
        name: '髮型',
        num: 1,
        address: 'XXXXXXXXXXXXXXXXXXXXXX'
      }
  ];
const MyWallet = () => {
    return (<div className='my-wallet-box content-margin-top'>
        <Row justify='space-between' align='middle' className='my-title'>
             <Col span={12}>
                <div className='color-text my-box-title-top'>錢包地址：<span className='my-box-ass'>092w8y8490587-93460wedy7gd98g</span></div>
                <div className='color-text'>加密貨幣餘額：<span className=' my-box-title-num my-box-title-num1'>123.45 XX幣</span> <span className='color-text '>NFT：</span> <span className='my-box-title-num'>3 枚</span></div>
             </Col>
             <Col span={12}>
                <Button className='bg-btn' >查看收支明細</Button>
             </Col>
        </Row>
        <TableComponent columns={columns} data={data}/>
        <div>
            <Button className='back-btn'>返回</Button>
        </div>
    </div>)
}
export default MyWallet