import React, {useEffect, useState} from 'react';
import { Button, Space, Modal, Spin, Form, Input, Switch, Popconfirm, message } from 'antd';
import { useNavigate} from 'react-router-dom'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import './WhitelistPage.css'
import TableComponent from '../../../components/tableComponent'
import {showAllWhiteLists, createNewWhitelist, createContract, checkProjectInfo} from '../../../api/index.js'
import { getsionStorage, removesionStorage } from '../../../utils/sionStorage';
const WhitelistPage = () =>{
    const projectId = getsionStorage('projectId')
    const columns = [

        {
          title: '排  序',
          className: 'index-box-sort',
          render:(_, record,index) => index+1
        },
        {
          title: '钱包地址',
          dataIndex: 'ownerAddress',
          key: 'ownerAddress',
        },
        // {
        //   title: '備      註',
        //   dataIndex: 'remarks',
        //   key: 'remarks',
        // },
        // {
        //     title: '名單數量',
        //     dataIndex: 'listNumber',
        //     key: 'listNumber',
        //   },
        // {
        //   title: '狀    態',
        //   key: 'listStatus',
        //   render: (_, record) => (
        //     <Space size="middle">
        //       <div className={_.listStatus === '1'? 'usestate': ''}>{_.listStatus === '1'? '停用': '启用'}</div>
        //     </Space>
        //   ),
        // },
        // {
        //   title: '操      作',
        //   key: 'action',
        //   render: (_, record) => (
        //     <Space size="middle">
        //       <Button className='compile-btn' onClick={() => editModel(_)}>编辑</Button>
        //       <Popconfirm
        //         title="确认删除吗?"
        //         onConfirm={confirm}
        //         onCancel={cancel}
        //         okText="确定"
        //         cancelText="取消"
        //       >
        //         <Button className='dele-btn'>删除</Button>
        //       </Popconfirm>
        //     </Space>
        //   ),
        // },
      ];
      const navigate = useNavigate()
      const [whiteLists, setWhiteLists] = useState([])// 白名单所有数据
      const [isModalVisible, setIsModalVisible] = useState(false); // 是否显示新增弹窗
      const [loading, setLoading] = useState(false); 
      const [ContractInfoloading, setContractInfoloading] = useState(false); 
      const [addwhiteList,setAddwhiteList] = useState({
        ownerAddressAll: []
      }) // 新增白名单数据
      // const [editwhiteList,seteditwhiteList] = useState({}) // 新增白名单数据
      // const [isAdd, setIsAdd] = useState(false);
      let checkProjecttimeinterVal; // 查询项目状态的定时器
      const [ownerAddressAll, setownerAddressAll] = useState([{
        ownerAddress1: ''
      }]) // 新增表单内的地址
      const [form] = Form.useForm()
      useEffect(async ()=>{
        if (!getsionStorage('projectId')){
          message.info('请先从第一步填写')
          removesionStorage('projectId')
          navigate('/createProject')
          return
        }
        getAllWhiteLists()
        checkProjectInfoTime(true)
      },[])
      useEffect(()=>{
        return () => {
            console.log("我被执行了")
            clearInterval(checkProjecttimeinterVal)
        }
    },[])
    const getAllWhiteLists = async () => {
      setLoading(true)
      try {
        const res = await showAllWhiteLists({projectId})
        setWhiteLists([...res])
      } catch{
      }
      setLoading(false)
    }
      // useEffect(() => {
      //   return () => {
      //     clearInterval(checkProjectInfoInterval)
      //   }
      // })
      // // 确认删除
      // const confirm = (e) => {
      //   console.log(e);
      // };
      // // 取消删除
      // const cancel = (e) => {
      //   console.log(e);
      // };
      // 显示新增图层
      const showModal = () => {
        // setIsAdd(true)
        form.setFieldsValue({...addwhiteList})
        setIsModalVisible(true);
      };
    
      const handleOk = () => {
        setIsModalVisible(false);
      };
    
      const handleCancel = () => {
        // setIsAdd(false)
        setIsModalVisible(false);
        // form.setFieldsValue({ownerAddressAll: []})
        setAddwhiteList({ownerAddressAll: []})
      };
      // 点击编辑model显示
      // const editModel = (data) => {
      //   setIsAdd(false)
      //   setIsModalVisible(true);
      //   seteditwhiteList({...data})
      //   data.listStatus = data.listStatus === '0'
      //   form.setFieldsValue({...data})
      // }
      // 新增表单数据改变事件
      const ValuesChange= (val, allValues) => {
        // for(const k in val){
        //   isAdd?setAddwhiteList({...addwhiteList, [k]: val[k]}): seteditwhiteList({...editwhiteList, [k]: val[k]})
        // }
        // for(const k in val){
        //   setAddwhiteList({...addwhiteList, [k]: val[k]})
        // }
        console.log(allValues)
        // let addwhiteListdata = [...addwhiteList.ownerAddressAll]
        // const index= val.ownerAddressAll.findIndex(item =>  item )
        // addwhiteListdata[index] = val.ownerAddressAll[index]
        // console.log('删除')
        setAddwhiteList({ownerAddressAll: [...allValues.ownerAddressAll]})
      }
      const addownerAddress = () => {
        const ownerAddressName = 'ownerAddress' + (ownerAddressAll.length + 1)
        setownerAddressAll([...ownerAddressAll, {
          [ownerAddressName]: ''
        }])
        setAddwhiteList({...addwhiteList, [ownerAddressName]: ''})
      }
      //提交表单成功回调
      const onFinish = (values) => {
        console.log('Received values of form:', values);
      };
      // 确定提交新增白名单
      const confirmownerAddress = async (e) => {
        try{
          form.validateFields();
          console.log(addwhiteList)
          let data = []
          if (addwhiteList.ownerAddressAll.length > 0){
            addwhiteList.ownerAddressAll.forEach(item => {
              data.push(item.first)
            })
          data = data.join(',')
          await createNewWhitelist({
            projectId,
            ownerAddress: data
          })
          handleCancel()
          const res = await showAllWhiteLists({projectId})
          setWhiteLists([...res])
          message.success('新增成功')
          }
        } catch{
        }
        // message.success('Click on Yes');
      };
      // 取消新增白名单
      const cancelownerAddress = (e) => {
        console.log(e);
        // message.error('Click on No');
      };
      // 点击下一步
      const nextStep = async () => {
        try{
          setLoading(true)
          await createContract({projectId})
          // message.info('提交成功,正在创建合约')
          // const {projectStatus} = await checkProjectInfo({projectId})
          // if (projectStatus == 7){
          //   navigator('/createProject/pageproducerPage')
          //   setLoading(false)
          // }
          checkProjectInfoTime()
        } catch{
          // clearInterval(checkProjectInfoInterval)
          setLoading(false)
        }
      }
      const checkProjectInfoTime = (loading) => {
        // loading?setContractInfoloading(loading): ''
        if (loading) {
          setContractInfoloading(loading)
        }
        // try{
        //   checkProjectInfoInterval =  setInterval(async() => {
        //     console.log('定时器')
        //     const {projectStatus} = await checkProjectInfo({projectId})
        //     if (projectStatus == 7){
        //       setContractInfoloading(false)
        //       clearInterval(checkProjectInfoInterval)
        //       navigate('/createProject/pageproducerPage')
        //       setLoading(false)
        //     }
        //   }, 6000);
        // } catch {
        //   clearInterval(checkProjectInfoInterval)
        // }
        checkProjecttimeinterVal = setInterval ( async () => {
          console.log('定时器')
          try{
                const { projectStatus } = await checkProjectInfo({projectId})
                if (projectStatus == 7){
                  setContractInfoloading(false)
                  clearInterval(checkProjecttimeinterVal)
                  navigate('/createProject/pageproducerPage')
                  setLoading(false)
                  return
                } else if (projectStatus < 6) {
                  clearInterval(checkProjecttimeinterVal)
                  setContractInfoloading(false)
                }
                return
          } catch{
          }
        }, 6000)
      }
    return <div className='white-list-box content-margin-top'>
        <div className='add-white-box'>
            <Button shape='round' className='add-white-btn' onClick={showModal}>新增</Button>
        </div>
        <Spin tip='正在创建合约' spinning={ContractInfoloading}>
        <TableComponent columns={columns} data={whiteLists}/>
        <Button className='bg-btn' style={{width: '15%',marginTop: '3vh', height: '4vh'}} onClick={nextStep}>创建合约</Button>
        </Spin>
        <Modal title='新增白名单' visible={isModalVisible} onCancel={handleCancel} footer={null}>
          {/* <Button type='primary' style={{marginBottom: '20px'}} onClick={addownerAddress}>新增地址</Button> */}
          {/* <Form 
            form={form} 
            name="dynamic_rule"
            initialValues= {addwhiteList}
            onValuesChange={(changedValues)=> ValuesChange(changedValues,isAdd)}
            >
            {/* <Form.Item
              name="adress"
              label="钱包地址"
              validateTrigger= 'onBlur'
              rules={[
                {
                  required: true,
                  message: 'Please input your nickname',
                },
              ]}
            >
              <Input placeholder="请输入钱包地址" />
            </Form.Item> */}
            {/* {ownerAddressAll.map((item,i) => {
              return <Form.Item
              name={'ownerAddress' + (i +1)}
              label={'钱包地址' + (i +1)}
              validateTrigger= 'onBlur'
              rules={[
                {
                  required: true,
                  message: 'Please input your nickname',
                },
              ]}
            >
              <Input placeholder="请输入钱包地址" />
            </Form.Item>
            })}
            <Form.Item labelAlign='right'>
              <div style={{float: 'right'}}>
                <Button style={{marginRight: '20px'}}>
                    取消
                  </Button>
                  <Button type="primary">
                    确定
                  </Button>
              </div>
            </Form.Item> */}
          {/* </Form> */}
          <Form 
          form={form}
          name="dynamic_form_nest_item" 
          className='fromadress-box' 
          onFinish={onFinish} autoComplete="off" 
          initialValues= {addwhiteList} 
          onValuesChange={(changedValues,allValues)=> ValuesChange(changedValues, allValues)}>
            <Form.List name="ownerAddressAll">
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: 'flex',
                          marginBottom: 8,
                        }}
                        align="baseline"
                        className='fromadress-box-item'
                      >
                        <Form.Item
                          {...restField}
                          label= {'钱包地址' + (key +1)}
                          name={[name, 'first']}
                          validateTrigger= {['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              pattern: /^[0-9A-Za-z]{0,}$/,
                              message: '数字＋字母组合',
                            },
                          ]}
                        >
                          <Input placeholder="钱包地址"/>
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => {
                          console.log(name)
                          let addwhiteListdata = [...addwhiteList.ownerAddressAll]
                          addwhiteListdata = addwhiteListdata.splice(name,1)
                          console.log(addwhiteListdata)
                          setAddwhiteList({ownerAddressAll: [...addwhiteListdata]})
                          remove(name)
                        }} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        新增地址
                      </Button>
                    </Form.Item>
                  </>
                )
              }}
            </Form.List>
            <Form.Item labelAlign='right'>
                    <div style={{float: 'right'}}>
                      <Button style={{marginRight: '20px'}} onClick={handleCancel}>
                          取消
                        </Button>
                        <Popconfirm
                          title="确定提交吗?提交后不可更改和删除白名单"
                          onConfirm={confirmownerAddress}
                          onCancel={cancelownerAddress}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="primary" htmlType="submit">
                          确定
                        </Button>
                        </Popconfirm>
                    </div>
                  </Form.Item>
        </Form>
      </Modal>
      <Spin tip="Loading..." spinning={loading}/>
    </div>
}
export default WhitelistPage