import { Button } from 'antd'
import {DeleteOutlined} from '@ant-design/icons'
import './caseCard.css'
import img1 from '../../assets/success1.png'
import { useNavigate } from 'react-router-dom'
import { setsionStorage } from '../../utils/sionStorage'
import { checkProjectInfo} from '../../api'
import { setData } from '../../actions/action'
import { connect } from 'react-redux'
import moment from 'moment';
const CaseCardData = (props) => {
    const {ProjectInfoAll, name, data, setData} = props
    // console.log(props, ProjectInfoAll, name)
    const navigate = useNavigate()
    const projectClick = async (status, ids) => {
        setsionStorage('projectId', ids)
        try {
            const datas = await checkProjectInfo({projectId: ids})
            Reflect.set(datas, 'releaseDate', moment(datas.releaseDate))
            const {projectName, price,currency,isBlind,issueNumber,releaseDate, publisher,walletAddress,generateNumber,id, projectStatus} = datas
            projectStatus = datas.projectStatus
            // await setData({projectName, price,currency,isBlind,issueNumber,releaseDate, publisher,walletAddress,generateNumber,projectStatus,projectId:id})
            await setData({projectName, price,currency,isBlind,issueNumber,releaseDate, publisher,walletAddress,generateNumber,projectStatus})
            // const imgsData = await showAllPictureFiles({projectId})
            // console.log(imgsData)
        } catch (err){
            console.log(err)
        }
        // const setImgsData = () => {
        //     const data = showAllPictureFiles({projectId})
        //     console.log(data)
        // }
        if (status >= 6){
            navigate('/createProject/pageproducerPage')
        } else if (Number(status)  < 6 && Number(status) >= 4) {
            navigate('/createProject/whitelistPage')
        } else if (status < 4 && status >= 2) {
            navigate('/createProject/generateWork')
        } else {
            navigate('/createProject/uploadImgPage')
        }
    }
    const Li = () => {
        if (ProjectInfoAll && ProjectInfoAll.length> 0) {
            return ProjectInfoAll.map(item => {
                return <li onClick={(e) => {projectClick(item.projectStatus, item.id)}}>
                        <div className='case-card-img'><img src={img1}></img></div>
                        {/* <div className='SuccessCase-content-name'>名稱名稱名稱001</div> */}
                        {name === 'itemRecord' ? (<div className='SuccessCase-content-name case-card-name-box'>
                            <div className='case-card-name'>
                                <div className='case-card-name-title'>{item.projectName}</div>
                                <div className='case-card-name-time'>{item.createDate}</div>
                            </div>
                            {/* <Button className='case-card-delete-btn' danger shape='round' icon={<DeleteOutlined />} type='text'>删除项目</Button> */}
                        </div>) : (<div className='SuccessCase-content-name'>名稱名稱名稱001</div>)}
                    </li>
              }
            )
        }
        return null
    }
    return (
        <div>
        <ul className='SuccessCase-content'>
            {/* <li> */}
                {/* <div className='case-card-img'><img src={img1}></img></div> */}
                {/* <div className='SuccessCase-content-name'>名稱名稱名稱001</div> */}
                {/* {name === 'itemRecord' ? (<div className='SuccessCase-content-name case-card-name-box'>
                    <div className='case-card-name'>
                        <div>名稱名稱名稱001</div>
                        <div className='case-card-name-time'>2020.8.25</div>
                    </div>
                    <Button className='case-card-delete-btn' danger shape='round' icon={<DeleteOutlined />} type='text'>删除项目</Button>
                </div>) : (<div className='SuccessCase-content-name'>名稱名稱名稱001</div>)}
            </li> */}
            <Li></Li>
        </ul>
    </div>
    )
}
const mapStateToProps = (state) => {
    return {
        data: state.material.data
    }
}
const mapDispatchToProps = {setData}
const CaseCard = connect(mapStateToProps, mapDispatchToProps)(CaseCardData)
export default CaseCard