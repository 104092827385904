import React from "react";
import NavMenu from '../../../components/navMenu';
import CaseComponent from "../../../components/case";
import {Button} from 'antd';
const CaseLists = () => {
    const navmenuList = [
        {text: '發行資費方案', path: '/expensesCase/caseLists'},
        {text: '賦能資費方案', path: '/expensesCase/caseLists'},
        {text: '賦能資費方案', path: '/expensesCase/caseLists'},
    ]
    return (<div className="content-box case-lists">
        <NavMenu navmenuList={navmenuList}/>
        <div className="expenses-case-content">
            <CaseComponent/>
            <CaseComponent/>
            <CaseComponent/>
        </div>
        <div>
            <Button className="back-btn" style={{float: 'unset', marginTop: '2em'}}>下一步</Button>
        </div>
    </div>)
}
export default CaseLists