import React, {Component} from 'react';
import { Carousel, Col, Row } from 'antd';
import {Link} from 'react-router-dom'
import scrop1 from '../../assets/scrop-1.png'
import './index.css';
import success1 from '../../assets/success1.png'
import logo from '../../assets/logo.png'
import fenxiang from '../../assets/fenxiang.png'
// 轮播图组件
  const CarouselComponent = () => {
    const onChange = (currentSlide) => {
      console.log(currentSlide);
    };
  
    return (
      <Carousel afterChange={onChange}>
        <div>
          <img src={scrop1}></img>
        </div>
        <div>
            <img src={scrop1}></img>
        </div>
        <div>
            <img src={scrop1}></img>
        </div>
      </Carousel>
    );
  };
   // 解决NFT发行难题部分de jsx
  const IssueProblemJxs = <div className='IssueProblem-box'>
    <div className='title'>發行NFT 就是這麼容易</div>
    <div className='IssueProblem-nav-box'>
        <div className='IssueProblem-nav-title'>無痛解決NFT發行難題</div>
        <div className='IssueProblem-nav-content'>
            <ul className='IssueProblem-nav-list'>
                <li>
                    <div className='IssueProblem-nav-list-li-div'>
                        <div>免建團隊</div>
                    </div>
                </li>
                <li>
                    <div className='IssueProblem-nav-list-li-div'>
                        {/* <div className='top'>免建團隊</div> */}
                        <div>免寫代碼</div>
                    </div>
                    </li>
                <li><div className='IssueProblem-nav-list-li-div'>
                        <div className='top'>無須購買</div>
                        <div>伺服器</div>
                    </div></li>
                <li>
                    <div className='IssueProblem-nav-list-li-div'>
                        <div className='top'>發行難題 </div> 
                        <div>一站解決</div>
                    </div>
                    </li>
                <li>
                    <div className='IssueProblem-nav-list-li-div'>
                        <div className='top'>費用彈性</div> 
                        <div>用多少 付多少</div>
                    </div>
                </li>
            </ul>
        </div>
        <div className='IssueProblem-nav-mes'>創新的BaaS (Blockchain as a Service) 服務架構，免下載安裝，純線上操作，進行簡單設定，發行問題一站解決</div>
    </div>
  </div>
  // 解决NFT发行难题部分的组件
class IssueProblem extends Component{
    render(){
        return (IssueProblemJxs)
    }
}
// NFT Launchpad 技術特點模块
 // NFT Launchpad 技術特點模块jsx
const TechnicalFeatureJSX= <div className='TechnicalFeature-box'>
    <div className='TechnicalFeature-title'>NFT Launchpad 技術特點</div>
    <div className='TechnicalFeature-content'>
        <div className='TechnicalFeature-content-chirdbox'>
            <div className='TechnicalFeature-content-chirdbox-top'>泛用性廣</div>
            <div className='TechnicalFeature-content-chirdbox-mes'>
                <div className='top'>所有基於乙太坊公鏈/</div>
                <div>側鏈均可適用</div>
            </div>
        </div>
        <div className='TechnicalFeature-content-chirdbox'>
            <div className='TechnicalFeature-content-chirdbox-top'>設定簡單</div>
            <div className='TechnicalFeature-content-chirdbox-mes'>
                <div className='top'>只需依照引導步驟</div>
                <div>進行設定及上傳資料</div>
            </div>
        </div><div className='TechnicalFeature-content-chirdbox'>
            <div className='TechnicalFeature-content-chirdbox-top'>彈性靈活</div>
            <div className='TechnicalFeature-content-chirdbox-mes'>
                <div className='top'>直接生成應用網頁</div>
                <div>亦可嵌入自行開發</div>
            </div>
        </div>
    </div>
</div>
// NFT Launchpad 技術特點模块组件
class TechnicalFeature extends Component{
    render(){
        return (TechnicalFeatureJSX)
    }
}
// 成功案例模块
const SuccessCaseJSX = <div className='index-SuccessCase-box'>
    <div className='index-SuccessCase-title'>成功案例</div>
    <div>
        <ul className='index-SuccessCase-content'>
            <li>
                {/* <img src={success1}></img> */}
                <div className='index-SuccessCase-content-name'>名稱名稱名稱001</div>
            </li>
            <li>
                {/* <img src={success1}></img> */}
                <div className='index-SuccessCase-content-name'>名稱名稱名稱001</div>
            </li>
            <li>
                {/* <img src={success1}></img> */}
                <div className='index-SuccessCase-content-name'>名稱名稱名稱001</div>
            </li>
            <li>
                {/* <img src={success1}></img> */}
                <div className='index-SuccessCase-content-name'>名稱名稱名稱001</div>
            </li>
            <li>
                {/* <img src={success1}></img> */}
                <div className='index-SuccessCase-content-name'>名稱名稱名稱001</div>
            </li>
            <li>
                {/* <img src={success1}></img> */}
                <div className='index-SuccessCase-content-name'>名稱名稱名稱001</div>
            </li>
            <li>
                {/* <img src={success1}></img> */}
                <div className='index-SuccessCase-content-name'>名稱名稱名稱001</div>
            </li>
            <li>
                {/* <img src={success1}></img> */}
                <div className='index-SuccessCase-content-name'>名稱名稱名稱001</div>
            </li>
        </ul>
    </div>
</div>
class SuccessCase extends Component{
    render (){
        return (SuccessCaseJSX)
    }
}
// 底部模块
const FooterJSX = <div className='footer-box'>
    <Row justify='space-between'>
      <Col className='footer-left'>
        <img src={logo} className='footer-left-logo'></img>
        <div className='footer-left-text'>©  2022 </div>
      </Col>
      <Col>
        <Row gutter={20} justify='end'>
            <Col>
                <Link to='#' className='icon-box'><img src={fenxiang}></img></Link>
            </Col>
            <Col>
                <Link to='#' className='icon-box'><img src={fenxiang}></img></Link>
            </Col>
            <Col>
                <Link to='#' className='icon-box'><img src={fenxiang}></img></Link>
            </Col>
            <Col>
                <Link to='#' className='icon-box'><img src={fenxiang}></img></Link>
            </Col>
        </Row>
        <div className='footer-right-bom'>nftempower@metawave.click</div>
      </Col>
    </Row>
</div>
// 底部模块组件
class Footer extends Component {
    render (){
        return (FooterJSX)
    }
}
export default class Index extends Component{
    render(){
        return (<div>
            <div className='middle-box'>
                <CarouselComponent/>
                <IssueProblem/>
                <TechnicalFeature/>
                <SuccessCase/>
            </div>
            <div className="border-bom"></div>
            <Footer/>
        </div>)
    }
}