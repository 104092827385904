import React from 'react';
import TableComponent from '../../../components/tableComponent';
import { Button } from 'antd';
const columns = [

    {
      title: '排  序',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: '圖層名稱備註',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '位      址',
      dataIndex: 'address',
      key: 'address',
    },
    {
        title: '檔案數量',
        dataIndex: 'num',
        key: 'num',
      },
    {
      title: '稀  有  度',
      key: 'uncommon',
      dataIndex: 'uncommon'
    },
  ];
  const data = [
    {
      key: '1',
      index: 1,
      name: '髮型',
      num: 1,
      address: 'XXXXXXXXXXXXXXXXXXXXXX'
    },
    {
        key: '1',
        index: 1,
        name: '髮型',
        num: 1,
        address: 'XXXXXXXXXXXXXXXXXXXXXX'
      },
      {
        key: '1',
        index: 1,
        name: '髮型',
        num: 1,
        address: 'XXXXXXXXXXXXXXXXXXXXXX'
      },
      {
        key: '1',
        index: 1,
        name: '髮型',
        num: 1,
        address: 'XXXXXXXXXXXXXXXXXXXXXX'
      },
      {
        key: '1',
        index: 1,
        name: '髮型',
        num: 1,
        address: 'XXXXXXXXXXXXXXXXXXXXXX'
      }
  ];
const PaymentScheme = () => {
    return (<div className='content-margin-top'>
        <TableComponent columns={columns} data={data}/>
        <div>
            <Button className='back-btn'>返回</Button>
        </div>
    </div>)
}
export default PaymentScheme