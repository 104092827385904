import './GenerateWork.css'
import { Button, Transfer, Image, Spin, message  } from 'antd'
import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom'
import {checkProjectInfo, showAllNFTs, uploadNFTsToPinata} from '../../../api/index.js'
import { getsionStorage, removesionStorage } from '../../../utils/sionStorage';
    const GenerateWork = () => {
    const [mockData, setmockData] = useState([])
    const projectId = getsionStorage('projectId')
    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    // const [isOk, setisOk] = useState(true) 
    const [loading, setLoading] = useState(true); // 生成等待中的loading图标提示
    const navigate = useNavigate()
    let checkProjecttimeinterVal // 发送查看图片生成状态的定时器
    useEffect(async () => {
        if (!getsionStorage('projectId')){
            message.info('请先从第一步填写')
            removesionStorage('projectId')
            navigate('/createProject')
            return
          }
        checkProjecttime()
    },[])
    useEffect(()=>{
        return () => {
            console.log(checkProjecttimeinterVal)
            clearInterval(checkProjecttimeinterVal)
        }
    })
    const checkProjecttime = () =>{
        checkProjecttimeinterVal = setInterval(async () => {
        try{
            const data = await checkProjectInfo({projectId})
            if (data.projectStatus >= 3){
                clearInterval(checkProjecttimeinterVal)
                const data = await showAllNFTs({projectId})
                let mockData= []
                for(var i in data){
                    mockData.push({
                        key: data[i].number,
                        title: <Image src={data[i].qiniuurl} alt='' style={{width: '100%'}}></Image>
                    })
                }
                setmockData([...mockData])
                setLoading(false);
                return 
            } 
        }catch {
            setLoading(false);
            navigate('/createProject/uploadImgPage')
        }
        }, 6000)
    }
    const onChange = (nextTargetKeys, direction, moveKeys) => {
        console.log('targetKeys:', nextTargetKeys);
        console.log('direction:', direction);
        console.log('moveKeys:', moveKeys);
        setTargetKeys(nextTargetKeys);
    };

    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        console.log('sourceSelectedKeys:', sourceSelectedKeys);
        console.log('targetSelectedKeys:', targetSelectedKeys);
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    const onScroll = (direction, e) => {
        // console.log('direction:', direction);
        // console.log('target:', e.target);
    };  
    const nextStep = async() => {
        const data = await uploadNFTsToPinata({
            projectId,
            ids: [...targetKeys]
        })
        if (data){
            message.info('提交成功')
            navigate('/createProject/whitelistPage')
        }
    }  
    return (<div className="generate-work-box" >
        <Spin spinning={loading}>
            <Image.PreviewGroup>
                <Transfer
                dataSource={mockData}
                titles={['草稿集', '結果集']}
                targetKeys={targetKeys}
                selectedKeys={selectedKeys}
                onChange={onChange}
                onSelectChange={onSelectChange}
                onScroll={onScroll}
                render={(item) => item.title}
                />
            </Image.PreviewGroup>
            <Button className='bg-btn' style={{width: '15%',marginTop: '2vh', height: '4vh'}} onClick={nextStep}>下一步</Button>
        </Spin>
    </div>)
}
export default GenerateWork