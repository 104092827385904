import React from "react";
import { useNavigate } from "react-router-dom";
const withNavigate = (WrappedComponent) => {
    NavigateWrappedComponent.displayName = `NavigateWrappedComponent${getDisplayName(WrappedComponent)}`
    function NavigateWrappedComponent(props) {
        const navigate = useNavigate()
        return <WrappedComponent {...props} to={navigate}></WrappedComponent>
    }
    function getDisplayName (WrappedComponent) {
        return WrappedComponent.displayName || WrappedComponent.name || 'Component';
    }
    return NavigateWrappedComponent
}
export default withNavigate