import React from 'react';
import { Col, Row, Button } from 'antd';
import TableComponent from '../../../components/tableComponent';
import './MyAccount.css'
const columns = [

    {
      title: '排  序',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: '圖層名稱備註',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '位      址',
      dataIndex: 'address',
      key: 'address',
    },
    {
        title: '檔案數量',
        dataIndex: 'num',
        key: 'num',
      },
    {
      title: '稀  有  度',
      key: 'uncommon',
      dataIndex: 'uncommon'
    },
  ];
  const data = [
    {
      key: '1',
      index: 1,
      name: '髮型',
      num: 1,
      address: 'XXXXXXXXXXXXXXXXXXXXXX'
    },
    {
        key: '1',
        index: 1,
        name: '髮型',
        num: 1,
        address: 'XXXXXXXXXXXXXXXXXXXXXX'
      },
      {
        key: '1',
        index: 1,
        name: '髮型',
        num: 1,
        address: 'XXXXXXXXXXXXXXXXXXXXXX'
      },
      {
        key: '1',
        index: 1,
        name: '髮型',
        num: 1,
        address: 'XXXXXXXXXXXXXXXXXXXXXX'
      },
      {
        key: '1',
        index: 1,
        name: '髮型',
        num: 1,
        address: 'XXXXXXXXXXXXXXXXXXXXXX'
      }
  ];
const MyAccount = () => {
    return (<div className='my-account-box content-margin-top'>
        <Row justify='space-between' align='middle' className='my-title'>
             <Col span={12}>
                <div className='color-text my-box-title-top'>加密貨幣餘額： <span className='my-box-ass my-box-title-num'> 123.45 XX幣</span></div>
             </Col>
             <Col span={12}>
                <Button className='bg-btn' >充值</Button>
             </Col>
        </Row>
        <TableComponent columns={columns} data={data}/>
        <div className='my-account-btn-box'>
            <Button className='my-account-more'>更多明細</Button>
            <Button className='back-btn'>返回</Button>
        </div>
    </div>)
}
export default MyAccount