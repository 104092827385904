import axios from 'axios';
import { message } from 'antd';
import { getsionStorage } from './sionStorage';
const Axios = axios.create({
    baseURL: 'http://lanuchpad.zikudata.com/',
    // headers: {'Content-Type': 'multipart/form-data'},
    timeout: 6000,
})
// 请求拦截器
Axios.interceptors.request.use(config => {
    const token = getsionStorage('token')
    const tokens = 'X-Auth-Token'
    if (token){
        config.headers[tokens] = token
    }
    return config
})
// 响应拦截器
Axios.interceptors.response.use(res => {
   if (res.data.code === 200) {
    return res.data.data
   } else {
    message.warning(res.data.message)
    return Promise.reject(res)
   }
}, err =>{
    message.warning('请求错误,请重试')
    return  Promise.reject(err.response)
})
export default Axios