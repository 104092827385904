import React from 'react';
import NavMenu from '../../components/navMenu';
import { Outlet } from 'react-router-dom';
import './index.css'
const My = () => {
    const navmenuList = [
        {text: '個人基本資料', path: '/my'},
        {text: '我的錢包', path: 'myWallet'},
        {text: '我的帳戶', path: 'myAccount'},
        {text: '我的付費方案', path: 'paymentScheme'},
        {text: '项目记录', path: 'itemRecord'}
    ]
    return (<div  className='content-box'>
        <NavMenu navmenuList={navmenuList}/>
        <Outlet/>
    </div>)
}
export default My