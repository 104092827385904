import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import './material.css'
import { Col, Row, Form, Input, Select, Radio, DatePicker,Button   } from 'antd';
import material from '../../../assets/material.png'
import icon from '../../../assets/data.png'
import { changInput } from  '../../../actions/action.js'
import { connect } from 'react-redux'
import dateHook from '../../../utils/day.js'
import {createNewProject} from '../../../api/index.js'
import { setsionStorage, getsionStorage } from '../../../utils/sionStorage';
import moment from 'moment';
const MaterialPage = (props) => {
    const { data,  changInput } = props
    const useNavigtion = useNavigate()
    const projectId = getsionStorage('projectId')
    const [from] = Form.useForm()
    const [isSetData, setisSetData] = useState(false)
    // 下一步点击按钮事件
    // 表单提交成功按钮
    const successFinish = async() => {
        let propData = {...data, releaseDate: data.releaseDate._i}
            try {
                const {id} = await createNewProject(propData)
                setsionStorage('projectId', id)
            } catch {
            }
            // if (projectId) {
            //     try {
            //         const {id} = await createNewProject(propData)
            //         setsionStorage('projectId', id)
            //     } catch {
            //     }
            // } else {
            //     try {
            //         const {id} = await createNewProject(propData)
            //         changInput({'projectId': id})
            //         setsionStorage('projectId', id)
            //     } catch {
            //     }
            // }
        useNavigtion('/createProject/uploadImgPage')
    }
    useEffect(()=>{
        // console.log(data.releaseDate, !data.isMy)
        // if (data.projectStatus > 0) {
        //     setisSetData(true)
        // }
        if(data.releaseDate){
            Reflect.set(data, 'releaseDate', moment(data.releaseDate))
            from.setFieldValue({...data})
        }
    })
    return <div className='content-margin-top'>
        <div className='material-title'>項目基本資料填寫</div>
        <div className='material-content-box'>
            <Row justify='space-around' align='middle' gutter={100}>
                <Col span={14}>
                    <Form 
                    from={from}
                    colon={false}
                    initialValues={data}
                    onValuesChange= {(changedValues)=> {
                        console.log(changedValues)
                        for(let k in changedValues){
                            k==='releaseDate'&&changedValues? changInput({name:k,val: dateHook(changedValues[k]._d)}): changInput({name:k,val: changedValues[k]})
                        }
                    }}
                    onFinish={successFinish}
                    autoComplete="off">
                    <Form.Item
                        name='projectName'
                        labelCol={8}
                        labelAlign= 'left'
                        label="項目名稱"
                        validateTrigger= 'onBlur'
                        rules={[
                            {
                                required: true,
                                message: '项目名称不能为空',
                            }   
                            ]}      
                    >
                        <Input disabled={isSetData}/>
                    </Form.Item>
                    <div className='flex-box'>
                        <Form.Item
                            name='price'
                            labelCol={8}
                            validateTrigger= 'onBlur'
                            labelAlign= 'left'
                            rules={[
                                {
                                    required: true,
                                    message: '价格不能为空',
                                }   
                                ]} 
                            label= '價&ensp;&ensp; &ensp;  &ensp;  &ensp; &ensp;格'
                        >
                            <Input disabled={isSetData}/>
                        </Form.Item>
                        <Form.Item
                            name='currency'
                            labelCol={8}
                            labelAlign= 'left'
                            label="幣&ensp;  &ensp; &ensp;  &ensp; &ensp;  &ensp;種"
                        >
                            <Select disabled={isSetData}>
                                <Option value="eth">CHZ</Option>
                                <Option value="matic">MATIC</Option>
                                <Option value="sand">SAND</Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className='flex-box isradio-box'>
                        <Form.Item
                            name='isBlind'
                            labelCol={8}
                            labelAlign= 'left'
                            label="是否盲盒"
                            className='isradio-box-left'
                        >
                            <Radio.Group disabled={isSetData}>
                                <Radio value='0'>是</Radio>
                                <Radio value='1'>否</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            name='releaseDate'
                            labelCol={8}
                            labelAlign= 'left'
                            label="解盲時間"
                            format= 'YYYY-MM-DD, hh:mm:ss'
                            validateTrigger= 'onBlur'
                            className='isradio-box-right'
                            rules={[
                                {
                                    required: true,
                                    message: '时间不能为空',
                                }   
                                ]} 
                        >
                            <DatePicker className='dataPicker-box' suffixIcon={<img src={icon}></img> } disabled={isSetData}/>
                        </Form.Item>
                    </div>
                    <Form.Item
                        labelCol={8}
                        labelAlign= 'left'
                        label="發  行  方"
                        name='publisher'
                        validateTrigger= 'onBlur'
                        rules={[
                            {
                                required: true,
                                message: '发行方不能为空',
                            }   
                            ]} 
                    >
                        <Input disabled={isSetData}/>
                    </Form.Item>
                    <Form.Item
                        labelCol={8}
                        labelAlign= 'left'
                        label="錢包位址"
                        name='walletAddress'
                        validateTrigger= 'onBlur'
                        rules={[
                            {
                                required: true,
                                message: '钱包地址不能为空',
                            }   
                            ]} 
                    >
                        <Input disabled={isSetData}/>
                    </Form.Item>
                    <Form.Item>
                        <Button className='submit-btn' htmlType='submit'>下一步</Button >
                    </Form.Item>
                    </Form>
                </Col>
                <Col span={10} className='material-img'>
                    <img src={material}></img>
                </Col>
            </Row>
        </div>
    </div>
}
const mapStateToProps = (state) => {
    return {
        data: state.material.data
    }
}
const mapDispatchToProps = {changInput}
const createProjectMaterialPage = connect(mapStateToProps, mapDispatchToProps)(MaterialPage)
export default createProjectMaterialPage